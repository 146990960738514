import React, { useState, useEffect } from 'react';

const ListPools: React.FC = () => {
  const [pools, setPools] = useState<string[]>([]);

  useEffect(() => {
    const fetchPools = async () => {
      const response = await fetch('https://api.lottery.cosgrove.live/list-pools');
      if (response.ok) {
        const data = await response.json();
        setPools(data);
      } else {
        alert('Failed to fetch pools.');
      }
    };

    fetchPools();
  }, []);

  return (
    <div>
      <h1>List of Lottery Pools</h1>
      <ul>
        {pools.map((pool, index) => (
          <li key={index}>{pool}</li>
        ))}
      </ul>
    </div>
  );
};

export default ListPools;
