import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import CreatePool from './CreatePool';
import ListPools from './ListPools';

const App: React.FC = () => {
  return (
    <Router>
      <div>
        <nav>
          <ul>
            <li>
              <Link to="/create-pool">Create Pool</Link>
            </li>
            <li>
              <Link to="/list-pools">List Pools</Link>
            </li>
          </ul>
        </nav>
        <Routes>
          <Route path="/create-pool" element={<CreatePool />} />
          <Route path="/list-pools" element={<ListPools />} />
          <Route path="/" element={<h1>Welcome to the Lottery Pool App</h1>} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
