import React, { useState } from 'react';

const CreatePool: React.FC = () => {
  const [name, setName] = useState('');

  const handleCreatePool = async () => {
    const response = await fetch('https://api.lottery.cosgrove.live/create-pool', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name }),
    });
    if (response.ok) {
      alert('Pool created successfully!');
    } else {
      alert('Failed to create pool.');
    }
  };

  return (
    <div>
      <h1>Create a Lottery Pool</h1>
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Pool Name"
      />
      <button onClick={handleCreatePool}>Create Pool</button>
    </div>
  );
};

export default CreatePool;
